import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import Accordion from '@shared/ui/layout/Accordion';
import { useAppSelector } from '@app/store/Hooks';
import { ContainerColumn } from '@components/styled';
import { Title } from '@components/styled/Typography';
import { IsDefined } from '@utils/Helpers';
import { ARTICLES_MOCKS, FAQ_MOCKS } from './mock';
import ArticleCardList from './ArticleCardList';
import SendRequestForm from './SendRequestForm';
import SupportBanner from './SupportBanner';
const Container = styled(ContainerColumn)(props => ({
    maxWidth: 1320,
    alignSelf: 'center',
    gap: props.theme.spacing_sizes.l,
    paddingTop: props.theme.spacing_sizes.s * 5,
}));
const BlockContainer = styled(ContainerColumn)(props => ({
    boxSizing: 'border-box',
    padding: `${props.theme.spacing_sizes.s * 5}px ${props.theme.spacing_sizes.s * 5}px
            ${props.theme.spacing_sizes.m}px`,
    background: '#FFF',
    borderRadius: 10,
}));
const ArticlesBlockContainer = styled(BlockContainer)(props => ({
    gap: props.theme.spacing_sizes.l,
    padding: props.theme.spacing_sizes.s * 5,
}));
const BlockTitle = styled(Title)(() => ({
    lineHeight: '22px',
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 'unset',
}));
const RequestFormBlock = styled(BlockContainer)(props => ({
    padding: `${props.theme.spacing_sizes.s * 6}px ${props.theme.spacing_sizes.s * 5}px`,
}));
const Support = () => {
    var _a;
    const { t } = useTranslation();
    const { currentUserProfile } = useAppSelector(store => store.profile);
    const isAuthorized = IsDefined(currentUserProfile);
    const list = useMemo(() => FAQ_MOCKS.map(({ text, title }, index) => ({
        isOpen: false,
        children: text,
        title,
        sectionKey: `accordion-${index}`,
    })), []);
    const handleRequestSend = (_) => { };
    return (_jsxs(Container, { children: [_jsx(SupportBanner, {}), _jsxs(BlockContainer, { children: [_jsx(BlockTitle, { children: t('support.faq', { ns: 'translation' }) }), _jsx(Accordion, { list: list })] }), _jsxs(ArticlesBlockContainer, { children: [_jsx(BlockTitle, { children: t('support.articles.title', { ns: 'translation' }) }), _jsx(ArticleCardList, { list: ARTICLES_MOCKS })] }), _jsxs(RequestFormBlock, { children: [_jsx(BlockTitle, { children: t('support.send_request.title') }), _jsx(SendRequestForm, { onSubmit: handleRequestSend, imageUrl: (_a = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.profilePicture) === null || _a === void 0 ? void 0 : _a.thumbnail, nickname: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.username, publicName: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.displayName, isAuthorized: isAuthorized })] })] }));
};
export default Support;
